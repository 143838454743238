export const colors = {
  green100: "#E6ECEB",
  green400: "#9BB1B0",
  green500: "#517774",
  green600: "#063D39",
  green700: "#05312E",

  greyscale0: "#FFFFFF",
  greyscale50: "#F5F5F5",
  greyscale100: "#E8E8E8",
  greyscale200: "#D4D4D3",
  greyscale300: "#B3B3B2",
  greyscale400: "#737372",
  greyscale500: "#525251",
  greyscale600: "#1D1D1B",

  lightblue500: "#E2F8FF",
  lightblue600: "#D6F5FF",

  navy: "#334454",

  offwhite600: "#F6F6F1",
  orange100: "#FFF5E9",
  orange400: "#FFD6A5",
  orange500: "#FFB862",
  orange600: "#FF991F",
  orange700: "#E68A1C",

  red: "#E56456",

  secondary500: "#44AADB",
  secondary600: "#1F82C0",

  success400: "#A4D99A",
  success600: "#67C057",
}

export type ColorKey = keyof typeof colors
