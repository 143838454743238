import React, { Children, ReactNode, TouchEventHandler, useState } from "react"

import { bullet, bulletSelected, bulletWrapper, slide, slidesWrapper } from "./syles.css"

type CarouselProps = {
  children: ReactNode
  onSwipe?: (nextSlideIndex: number) => void
}

export const Carousel: React.FC<CarouselProps> = ({ children, onSwipe }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [touchPosition, setTouchPosition] = useState<number | null>(null)

  if (!children) {
    return null
  }

  const next = () => {
    setCurrentIndex((prevState) => {
      if (prevState < Children.count(children) - 1) {
        onSwipe?.(prevState + 1)
        return prevState + 1
      } else {
        return prevState
      }
    })
  }

  const prev = () => {
    setCurrentIndex((prevState) => {
      if (prevState > 0) {
        onSwipe?.(prevState - 1)
        return prevState - 1
      } else {
        return prevState
      }
    })
  }

  const handleTouchStart: TouchEventHandler = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove: TouchEventHandler = (e) => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 5) {
      next()
    }
    if (diff < -5) {
      prev()
    }
    setTouchPosition(null)
  }

  const handleItemClick = (index: number) => {
    if (index !== currentIndex) {
      setCurrentIndex(index)
      onSwipe?.(index)
    }
  }

  return (
    <>
      <div className={slidesWrapper} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}>
        {Children.map(children, (item, index) => {
          return (
            <div
              key={index}
              className={`${slide}${currentIndex === index ? " selected" : ""}`}
              style={{ translate: `-${currentIndex * 100}% 0%` }}
              onClick={() => handleItemClick(index)}
            >
              {item}
            </div>
          )
        })}
      </div>

      <div className={bulletWrapper}>
        {Children.map(children, (_, index) => (
          <div
            key={index}
            className={`${bullet} ${index === currentIndex ? bulletSelected : ""}`}
            onClick={() => handleItemClick(index)}
          ></div>
        ))}
      </div>
    </>
  )
}
