import { Body, H5 } from "@casavo/habitat"
import Trans from "next-translate/Trans"
import useTranslation from "next-translate/useTranslation"

import { Clock } from "@app/shared/components/icons/Clock"
import { MyImage } from "@app/shared/components/MyImage"
import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"

import { StepCardType } from ".."

import { Badge } from "./Badge"
import { label, stepCard } from "./styles.css"

type Props = {
  className?: string
  item: StepCardType
}

export const StepCard: React.FC<Props> = ({ className, item }) => {
  const { badgeText, description, imageSrc, step, title } = item
  const { t } = useTranslation("sell")

  return (
    <div className={`${stepCard} ${className ? className : ""}`}>
      <MyImage
        alt={item.title}
        className={sprinkles({ marginBottom: "2xl" })}
        height={100}
        src={imageSrc}
        width={100}
      />

      <div
        className={sprinkles({
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "l",
        })}
      >
        <div className={label}>
          <Body noMargin color="foreground.text.hover" size="s">
            {t(step)}
          </Body>
        </div>
        {badgeText && <Badge Icon={<Clock />}>{t(badgeText)}</Badge>}
      </div>

      <div
        className={sprinkles({
          marginBottom: "s",
        })}
      >
        <H5 noMargin>{t(title)}</H5>
      </div>
      <Body noMargin size="s">
        <Trans
          components={{
            b: <b />,
          }}
          i18nKey={description}
        />
      </Body>
    </div>
  )
}
