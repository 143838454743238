import { Body } from "@casavo/habitat"

import { badge } from "./styles.css"

export const Badge = ({ Icon, children }: { Icon?: React.ReactElement; children: React.ReactNode }) => {
  return (
    <div className={badge}>
      {Icon}
      <Body noMargin color="foreground.text.primary" size="xs">
        {children}
      </Body>
    </div>
  )
}
